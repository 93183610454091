import Hades from '@services/Hades';
import { ConnectionState } from '@services/SendBird';
import { IRootStore } from '@stores/ApplicationInterfaces';
import Member from '@stores/Member';
import { getCloserDate, getRecentUpdater } from '@utils/dates';
import { formatDbMessage, DbMessage } from '@utils/formatDbMessage';
import { Logger } from '@utils/logger';
import { get, keyBy, last } from 'lodash';
import { flow, Instance } from 'mobx-state-tree';
import moment from 'moment-timezone';

function formatChannel(member: MemberToAdd) {
  const { channels } = member;
  const channel = last(channels);
  const messages = channel.messages
    ? channel.messages.map((dbMessage: DbMessage) => formatDbMessage(dbMessage, member.chatId))
    : [];

  return {
    ...channel,
    id: channel.id,
    url: channel.url,
    messages,
    lastReadAt: channel.lastReadAt
      ? moment(channel.lastReadAt)
      : moment().subtract(1, 'years'),
  };
}

export type MembersMixinActions = {
  getMemberMessages: () => Promise<any>;
  addMembers(items: AddMembersInput): void;
  loadMemberMessages: () => Promise<void>;
  setMember(
    idOrMember: string | undefined,
  ): Promise<false | Instance<typeof Member> | null | undefined>;
  setReportsMember(
    idOrMember: string | undefined,
  ): Promise<false | Instance<typeof Member>>;
  getMember(args: {
    memberId?: number;
    coachId?: string;
  }): Promise<Instance<typeof Member> | undefined>;
  removeMembers(ids: Array<string>): void;
  // @ts-ignore
  getMemberByChatId(chatId: string): Instance<typeof Member> | undefined;
};

export type MembersMixinViews = {
  membersArray: Instance<typeof Member>[];
  grabbedMembers: Instance<typeof Member>[];
  recentlyReleasedMembers: Instance<typeof Member>[];
  unassignedMembers: Instance<typeof Member>[];
  leftNavMembers: Instance<typeof Member>[];
  membersChatIdMap: Map<string, number>;
};

// TODO this should be completely typed once we integrate backend typings
export interface MemberToAdd {
  acute: boolean;
  chatId: string;
  channels: any[];
  coachId: string;
  employmentProfile: any;
  followUp: any;
  id: number;
  memberships: any[];
  NPSSurvey: any;
}

type AddMembersInput = MemberToAdd[];

const MembersMixin = {
  actions: (self: IRootStore) => ({
    getMemberMessages: flow(function* getMemberMessages() {
      const memberIds = self.leftNavMembers.map((member) => member.id);
      const result = yield (self.hades as Hades).request(
        'member.getMessageCount',
        {
          memberIds,
        },
      );

      Object.entries(result).map(async ([memberId, messageCountLast15Min]) => {
        const member = await self.getMember({ memberId: Number(memberId) });
        if (member) {
          member.setMessageCountLast15Min(messageCountLast15Min);
        }
      });
      return result;
    }),

    addMembers(items: AddMembersInput = []) {
      const mappedMembers = (items || [])
        .filter(
          (member) =>
            member.channels.length &&
            // https://sibly-jira.atlassian.net/browse/SIB-4021
            // do not modify the currently selected member, or else we run into scrolling issue
            self.member?.id !== member.id,
        )
        .map((member) => {
          const lastMembershipIndex = member.memberships.length - 1;
          return {
            ...member,
            acute: member?.acute || false,
            coach: member.coachId || undefined,
            channel: formatChannel(member),
            membership: {
              ...member.memberships[lastMembershipIndex],
              accessCode: member.memberships[lastMembershipIndex].accessCode.id,
              type: member.memberships[lastMembershipIndex].type,
            },
            isHidden:
              member.memberships[lastMembershipIndex]?.status ===
                'terminated' ||
              member.memberships[lastMembershipIndex]?.status === 'stopped' ||
              false,
            employmentProfile: member.employmentProfile,
            resources: {},
            tracks: [],
            dailySummary: {},
            followUp: {
              frequency: member.followUp?.frequency || 0,
              hour: member.followUp?.hour || 0,
              message: member.followUp?.message || '',
              updatedAt: member.followUp
                ? getCloserDate(member.followUp)
                : undefined,
              updatedBy: member.followUp
                ? getRecentUpdater(member.followUp)
                : null,
            },
            NPSSurvey: member?.NPSSurvey || {
              lastCompleted: undefined,
              sentOn: undefined,
            },
          };
        });

      const members = keyBy(mappedMembers, 'id');

      self.members = self.members.merge(members);
    },

    loadMemberMessages: flow(function* loadMemberMessages() {
      try {
        // if the connection wasnt started yet, it will eventually open when loadMostRecentMessages is executed
        // this is needed in the case of loading member messages using /dashboard/:memberId route
        // where this function could be called while the sendbird connection is being opened
        if (
          self.chat.sendbirdConnectionState !== ConnectionState.NOT_STARTED &&
          self.chat.sendbirdConnectionState !== ConnectionState.OPEN
        ) {
          self.showAlert(
            'chat service connection is not open. Try reloading the dashboard and check your internet connection.',
            'danger',
          );
          return;
        }
        yield self.chat.loadMostRecentMessages();
      } catch (error) {
        self.showAlert('There was an error loading messages.', 'danger', error);
      }
    }),

    setMember: flow(function* setMember(idOrMember: number | Instance<typeof Member> | undefined) {
      try {
        // to "unset" a member (would be better to have a seperate call for this to be explicit)
        if (!idOrMember) {
          Logger.log('unsetting currently set member');
          self.member = undefined;
          return false;
        }

        const id: number = get(idOrMember, 'id', idOrMember);
        Logger.log(`setting member with id ${id}`);

        if (self.member && self.member.id === id) {
          Logger.log(`member ${id} is already set, returning`);
          return false;
        }

        // clear channel and messages
        if (self.member) {
          self.member.isLoadingData = false;
          Logger.log(`clear channel and messages from member ${self.member.id}`);
          self.chat.resetChat();
          if (self.member.channel.lastMessage) {
            self.member.channel.messages.replace([
              self.member.channel.lastMessage,
            ]);
          }
          Logger.log(`finished clear channel and messages from member ${self.member.id}`);
        }

        self.drafts.saveDraftMessage();

        const member = yield self.getMember({ memberId: id });
        Logger.log(`setting member ${member.id} as self.member`);
        self.member = member.id;

        self.chat.input.clear();
        self.chat.input.setInitialValue();
        if (self.member) {
          Logger.log(`loading member ${member.id} data`);
          self.member.load();
        }
        Logger.log(`loading recent messages from member ${member.id}`);

        // self.loadMemberMessages is not awaited by design,
        // so that we can see the member data before messages finish loading
        // or even if they fail to load
        self.loadMemberMessages();

        if (self.member) {
          Logger.log(`MembersMixin:setMember memberId=${self.member.id}`);
          self.member.log('MembersMixin:setMember turning off detailed logging for member');
          self.member.disableLogging();
        }

        return self.member;
      } catch (error) {
        self.showAlert('Failed to get member data', 'danger', error);
        // remove member from chat
        self.member = undefined;
        self.chat.input.clear();
        return null;
      }
    }),

    setReportsMember: flow(function* setMember(idOrMember) {
      if (!idOrMember) {
        self.member = undefined;
        return false;
      }
      const id: number = get(idOrMember, 'id', idOrMember);
      if (self.member && self.member.id === id) {
        return false;
      }

      const member = yield self.getMember({ memberId: id });
      self.member = member;

      if (self.member) {
        self.member.load();
      }

      return self.member;
    }),

    // returns instance of a member and add to the store if member isnt already there
    getMember: flow(function* getMember({
      memberId,
      chatId,
    }: {
      memberId?: number;
      chatId?: string;
    }) {
      Logger.log(`getting member by: ${memberId ? 'memberId' : 'chatId'} with id ${memberId || chatId}`);
      if (memberId && self.members.has(memberId.toString())) {
        Logger.log(`member ${memberId} is in store`);
        return self.members.get(memberId.toString());
      }

      try {
        const params = memberId ? { memberId } : { chatId };

        const member = yield (self.hades as Hades).request(
          'member.get',
          params,
        );

        Logger.log(`fetched member ${member.id} data from backend`);

        member.organization = member.organization.id;
        if (member.notes) {
          member.notes = {
            ...Object.entries(member.notes)
              // @ts-ignore
              .map(([key, { coachId, text, updatedAt }]) => ({
                id: key,
                updatedBy: coachId,
                lastUpdated: updatedAt || null,
                text,
              })),
          };
        }

        Logger.log(`adding member ${member.id} to store`);
        self.addMembers([member] as AddMembersInput);

        return self.members.get(member.id);
      } catch (error) {
        throw new Error((error as Error).message);
      }
    }),

    removeMembers(ids: Array<string>) {
      ids.forEach((id) => {
        const member = self.members.get(id);
        if (!member) return;
        member.hide();
      });
    },

    getMemberByChatId(chatId: string) {
      const memberKey = self.membersChatIdMap.get(chatId);
      // @ts-ignore
      return self.members.get(memberKey);
    },

    setNextMemberId(id: number | undefined) {
      self.nextMemberId = id;
    },
  }),
  views: (self: IRootStore) => ({
    get membersArray() {
      return Array.from(self.members.values())
        .filter((member: Instance<typeof Member>) => {
          const { isHidden } = member;

          member.log(`MembersMixin:membersArray isHidden=${isHidden}`);

          return !isHidden;
        })
        .sort((memberA, memberB) => {
          if (
            !memberB.channel?.sortTimestamp ||
            !memberA.channel?.sortTimestamp
          ) {
            return -1;
          }
          return memberB.channel.sortTimestamp - memberA.channel.sortTimestamp;
        });
    },

    // get members grabbed by currently logged in coach
    get grabbedMembers() {
      return this.membersArray.filter((member: Instance<typeof Member>) => {
        const { me } = self;
        const { isGrabbed } = member;

        const grabbedByCoach = member.coach?.id;
        const isGrabbedByMe = me && isGrabbed && grabbedByCoach === me.id;

        member.log(`MembersMixin:grabbedMembers isGrabbed=${isGrabbed} grabbedByCoach=${grabbedByCoach} isGrabbedByMe=${isGrabbedByMe}`);

        return isGrabbedByMe;
      });
    },

    // get first 15 members that are not grabbed by any coach AND
    // they have already been assigned before(assignedAt) AND
    // their last message came before they were released
    get recentlyReleasedMembers() {
      return this.membersArray
        .filter((member: Instance<typeof Member>) => {
          const { isReleased } = member;

          member.log(`MembersMixin:recentlyReleasedMembers isReleased=${isReleased}`);

          return isReleased;
        })
        .slice(0, 15);
    },

    // members who:
    // have sent at least one message AND
    // they arent grabbed AND
    // they arent released(this could be the case of members who were never grabbed in first place)
    //   OR their last message date comes after the moment they were last assigned to a coach
    get unassignedMembers() {
      return this.membersArray.filter((member: Instance<typeof Member>) => {
        const {
          channel: { lastMemberMessageAt },
          isGrabbed,
          isReleased,
          momentAssignedAt,
        } = member;

        const lastMemberMessageIsAfterMomentAssignedAt =
          lastMemberMessageAt?.isAfter(momentAssignedAt);

        const isUnassigned = Boolean(
          lastMemberMessageAt &&
            !isGrabbed &&
            (!isReleased || lastMemberMessageIsAfterMomentAssignedAt),
        );

        member.log(`MembersMixin:unassignedMembers lastMemberMessageAt=${lastMemberMessageAt} isGrabbed=${isGrabbed} isReleased=${isReleased} momentAssignedAt=${momentAssignedAt} lastMemberMessageIsAfterMomentAssignedAt=${lastMemberMessageIsAfterMomentAssignedAt} isUnassigned=${isUnassigned}`);

        return isUnassigned;
      });
    },

    get leftNavMembers() {
      return [
        ...this.grabbedMembers,
        ...this.recentlyReleasedMembers,
        ...this.unassignedMembers,
      ];
    },

    get membersChatIdMap() {
      return new Map(
        Array.from(self.members.values()).map((member) => [
          member.chatId,
          member.id,
        ]),
      );
    },

    get myMemberIDsWithRecentlyReleased() {
      return JSON.stringify([
        ...this.grabbedMembers,
        ...this.recentlyReleasedMembers
      ].map((member) => member.id));
    }
  })
};

export default MembersMixin;
