import { SiblySDK } from '@sibly/sibly-sdk-browser';
import { useNudges } from '@utils/hooks/useNudges';
import { FunctionComponent, useEffect, useState, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

import { createBrowserHistory } from 'history';
import { Router, Redirect, RouteComponentProps } from '@reach/router';

import { observer } from 'mobx-react-lite';

import Alert from '@components/Alert';

import { PulseLoader } from 'react-spinners';
import useIdleDetection, { TabMessage } from '@utils/hooks/useIdleDetection';
import { Logger } from '@utils/logger';

import Header from './components/Header';
import LogoutModal from './components/LogoutModal';

import Store from './stores';

const Coaches = lazy(() => import('./pages/Coaches'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Login = lazy(() => import('./pages/Login'));
const Organizations = lazy(() => import('./pages/Organizations'));
const CoachConversations = lazy(() => import('./pages/CoachConversations'));
const Reports = lazy(() => import('./pages/Reports'));

type ProtectedRouteProps = {
  Component: FunctionComponent<object>;
} & RouteComponentProps;

const ProtectedRoute = observer(({ Component, ...rest }: ProtectedRouteProps) => {
  const history = createBrowserHistory();
  const { logout, dismissAlert, me } = Store;

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [
    isLogoutPromptBeingRenderedOnOtherTab,
    setIsLogoutPromptBeingRenderedOnOtherTab,
  ] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { user } = await SiblySDK.Authorization.getCurrentAuthenticatedUser();
        if (user) {
          setIsAuthenticated(true);
        }
      } catch {
        setIsAuthenticated(false);
      }
    })();
  }, []);

  useEffect(() => {
    dismissAlert();

    if (Store.member) {
      Store.setMember(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  const handleOnIdle = () => {
    if (isLogoutPromptBeingRenderedOnOtherTab) return;
    setShowLogoutModal(true);
  };

  const handleTabMessage = (data: any) => {
    // other tab was logged out by idle detection, log this tab out
    if (data?.type === TabMessage.LOGOUT) {
      Logger.log('received logout event');
      logout();
      setIsLogoutPromptBeingRenderedOnOtherTab(false);
    }
    // other tab rendered logout modal
    if (data?.type === TabMessage.RENDER_LOGOUT_MODAL) {
      Logger.log('received message RENDER_LOGOUT_MODAL');
      setIsLogoutPromptBeingRenderedOnOtherTab(true);
    }
    // other tab unmounted logout modal
    if (data?.type === TabMessage.UNMOUNT_LOGOUT_MODAL) {
      Logger.log('received message UNMOUNT_LOGOUT_MODAL');
      setIsLogoutPromptBeingRenderedOnOtherTab(false);
      setShowLogoutModal(false);
    }
  };

  const { pauseTimer, startTimer, messageTabs } = useIdleDetection({
    handleVisibleInactivity: handleOnIdle,
    handleHiddenInactivity: logout,
    handleTabMessage,
    isPromptBeingRenderedOnOtherTab: isLogoutPromptBeingRenderedOnOtherTab,
  });

  // start idle detection when user is authenticated
  // stop it when user is logged out
  useEffect(() => {
    if (isAuthenticated) {
      Logger.log('starting idle detection timer');
      startTimer();
    } else {
      Logger.log('pausing idle detection timer');
      pauseTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useNudges({ isAuthenticated, path: rest.path ?? '/' });

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
    setIsLogoutPromptBeingRenderedOnOtherTab(false);
    Logger.log('starting idle detection timer');
    startTimer();
    messageTabs({ type: TabMessage.UNMOUNT_LOGOUT_MODAL });
  };

  const handleModalLogout = () => {
    logout();
    Logger.log('logging out, communicate to all other tabs');
    // logged out, communicate to other tabs
    messageTabs({ type: TabMessage.LOGOUT });
    setIsLogoutPromptBeingRenderedOnOtherTab(false);
  };

  return (
    <>
      {isAuthenticated ? (
        <>
          <LogoutModal
            onExtendSession={handleCloseLogoutModal}
            onLogout={handleModalLogout}
            visible={showLogoutModal}
            coachId={me?.id as number}
          />
          <Header />
          <Component {...rest} />
        </>
      ) : (
        <Redirect from="" to="/" noThrow />
      )}
      <Alert />
    </>
  );
});

ProtectedRoute.displayName = 'ProtectedRoute';
ProtectedRoute.propTypes = {
  // @ts-ignore
  Component: PropTypes.elementType.isRequired,
};

function Loader() {
  return (
    <div className="d-flex align-items-center">
      <h1 className="text-white">Loading</h1>
      <PulseLoader color="#fff" />
    </div>
  );
}

export default observer(() => (
  <Suspense fallback={<Loader />}>
    <Router>
      <Login path="/" />
      <ProtectedRoute path="/coaches" Component={Coaches} />
      <ProtectedRoute path="/dashboard/" Component={Dashboard} />
      <ProtectedRoute path="/dashboard/:memberId" Component={Dashboard} />
      <ProtectedRoute path="/organizations" Component={Organizations} />
      <ProtectedRoute path="/reports" Component={Reports} />
      <ProtectedRoute path="/conversations" Component={CoachConversations} />
    </Router>
  </Suspense>
));
